/* WARNING: only use direct CSS overrides when a package does not support
 * styled-components or react object styles.
 *
 * All properties need to be marked `!important` or they will be overwritten by `react-calendar`
 */

.react-calendar {
  width: 100% !important;
  flex: 0 !important;
  border-radius: 4px !important;
  border: 1px solid #eeeeee !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1) !important;
}

.react-calendar__navigation {
  margin: 0 0 16px !important;
  height: 34px !important;
}

.react-calendar__navigation__label__labelText {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #222222 !important;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 14px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0px 0 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 20px !important;
  color: #666666 !important;
  text-transform: uppercase !important;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none !important;
}

.react-calendar__month-view__days {
  justify-content: space-between;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #000000 !important;
}

.react-calendar__navigation button[disabled] {
  background-color: #ffffff !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #ffffff !important;
}

.react-calendar__tile {
  flex-basis: 48px !important;
  height: 48px !important;
  padding: 10px 8px !important;
  margin: 1px 0px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: #000000;

}

.react-calendar__tile:disabled {
  background-color: #ffffff !important;
  color: #cacccd !important;
  font-weight: 400 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  color: #000000 !important;
  background-color: #FFFFFF !important;
  border: 1px solid #07BFF1 !important;
  border-radius: 8px !important;
}

.react-calendar__tile--active {
  background-color:#E0F8FF !important;
  color: #FFFFFF !important;
  border: none !important;
  border-radius: 100% !important;
}

.react-calendar__tile--active:enabled,
.react-calendar__tile--active:enabled {
  background-color:#E0F8FF !important;
  color: #222222 !important;
  border: 1px solid #07BFF1 !important;
  border-radius: 8px !important;
}

.react-calendar__tile--now {
  background-color: #ffffff !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #000000 !important;
}

.react-calendar__tile--active.react-calendar__month-view__days__day--weekend {
  color: #ffffff !important;
}

.react-calendar__navigation button {
  min-width: 48px;
}

@media screen and (max-width: 414px) {

  .react-calendar__navigation__label__labelText {
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .react-calendar__tile {
    flex-basis: 36px !important;
    height: 36px !important;
    padding: 10px 8px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
  }
}
