.swiper-pagination-bullet {
  transform: scale(1);
  width: 30px;
  height: 4px;
  border-radius: 100px;
  background: #ffffff;
  opacity: 1;
  margin: 0 5px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
}

.swiper-pagination-bullet-active {
  transform: scale(1);
  background: #ffe400;
  width: 30px;
  height: 4px;
  top: -1px;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  /* margin: 0; */
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(1);
  width: 30px;
  height: 4px;
  top: -1px;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(1);
  width: 30px;
  height: 4px;
  top: -1px;
}
