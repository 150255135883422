.x-hellosign-embedded__iframe {
  max-height: calc(100vh - 50px) !important;
}

/* above overlay and modals */
.x-hellosign-embedded--in-modal {
  z-index: 10002 !important;
}

.x-hellosign-embedded {
  height: calc(100vh - 50px - 20vh) !important;
  width: 100vw !important;
  position: absolute;
  bottom: -40vh;
}

@media (min-width: 768px) {
  .x-hellosign-embedded {
    height: calc(100vh - 70px) !important;
    width: 100% !important;
    bottom: -50vh;
  }

  .x-hellosign-embedded__iframe {
    max-height: calc(100vh - 70px) !important;
  }
}

@media (min-width: 1024px) {
  .x-hellosign-embedded {
    position: relative;
    bottom: unset;
  }
}
