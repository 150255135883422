html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  font-size: 16px;
  position: relative;
}

body, input, textarea, button {
  margin: 0;
  padding: 0;
  font-family: Avenir, Montserrat, "Helvetica Neue", Helvetica, Arial, san-serif;
}

input, select { font-size: 100%; }

body, html {
  width: 100%;
  position: relative;
}

html {
  scroll-padding-top: 150px;
}

.intercom-lightweight-app-launcher, .intercom-launcher {
  background: #FFA51E !important;
}

@font-face {
  font-family: "Avenir";
  font-weight: 300;
  src: url("../public/fonts/Avenir-Light.otf");
  font-display: block;
}

@font-face {
  font-family: "Avenir";
  font-weight: 400;
  src: url("../public/fonts/Avenir-Roman.otf");
  font-display: block;
}

@font-face {
  font-family: "Avenir";
  font-weight: 500;
  src: url("../public/fonts/Avenir-Medium.otf");
  font-display: block;
}

@font-face {
  font-family: "Avenir";
  font-weight: 800;
  src: url("../public/fonts/Avenir-Heavy.otf");
  font-display: block;
}

@font-face {
  font-family: "Avenir";
  font-weight: 900;
  src: url("../public/fonts/Avenir-Black.otf");
  font-display: block;
}

/* google optimize anti-flicker script */
.async-hide {
  opacity: 0 !important;
}

/* hide google recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}
